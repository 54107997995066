<!-- eslint-disable no-undef -->
<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.contratos')} / ${$t('menu.hotels')} / Eliminar Tarifarios`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'hotels-contrate-update' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                outlined
                :loading="loading"
                :disabled="verifyItems"
                @click="isDialogVisibleDelete = true"
              >
                <span>{{ $t('btn.delete') }}</span>
                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-form class="multi-col-validation">
            <v-row v-if="isLoading">
              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
              </v-col>

              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
              </v-col>

              <v-spacer></v-spacer>

              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
              </v-col>

              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>

            <template v-else>
              <v-row>
                <v-col
                  v-if="model.proveedor_id"
                  cols="12"
                  md="4"
                >
                  <p class="mt-3">
                    <strong class="ml-10">{{ $t('lbl.proveedor') }}</strong>: {{ model.proveedor.name_comercial }}
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <p class="mt-3">
                    <strong class="ml-10">{{ $t('lbl.identificador') }}</strong>: {{ model.identificador }}
                  </p>
                </v-col>
              </v-row>
            </template>
          </v-form>

          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <template v-if="!isLoadingHotels">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          class="text-uppercase"
                          width="5%"
                        >
                          <v-checkbox
                            v-model="checkAll"
                            hide-details
                            class="my-0"
                            @change="changeCheckAll"
                          ></v-checkbox>
                        </th>
                        <th
                          class="text-uppercase"
                          width="40%"
                        >
                          {{ $t('lbl.hotel') }}
                        </th>
                        <th
                          class="text-uppercase"
                          width="5%"
                        >
                          Code
                        </th>
                        <th
                          class="text-uppercase"
                        >
                          {{ $t('lbl.destino') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('menu.typeDestination') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('menu.operador') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.tarifa') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <fragment
                        v-for="(iten, indP) in hotels"
                        :key="indP"
                      >
                        <tr v-if="iten.id === iten.id">
                          <td>
                            <v-checkbox
                              v-model="iten.check"
                              hide-details
                              class="my-0"
                              @change="changeCheckItem"
                            ></v-checkbox>
                          </td>
                          <td>
                            <NameHotelStar :hotel="iten" />
                          </td>
                          <td>{{ iten.code_hotel_externo }}</td>
                          <td>
                            {{ `${iten.city ? `${iten.city}, ` : ''}` }}
                            <span v-if="iten.country">{{ iten.country }}</span>
                          </td>
                          <td>
                            <span v-if="iten.tipo_destino_id">
                              {{ iten.tipo_destino }}
                            </span>
                          </td>
                          <td>
                            <span v-if="iten.operador_id">
                              {{ iten.operador }}
                            </span>
                          </td>
                          <td>
                            <span
                              v-for="(season, ind) in seasons"
                              :key="ind"
                            >
                              <span v-if="season.hotels.includes(iten.id)">
                                <span v-if="season.seasons">
                                  <span v-if="season.seasons.tarifas">
                                    <span
                                      v-for="(tarifa, indT) in season.seasons.tarifas"
                                      :key="indT"
                                    >
                                      {{ tarifa.name }}<br />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </td>
                        </tr>
                      </fragment>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
              <v-skeleton-loader
                v-else
                class="mx-auto"
                type="table-tbody"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card-text>
      </app-card-code>
    </v-col>

    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          ¿Está seguro que desea eliminar los tarifarios de los hoteles seleccionados?
          <br />
          <br />
          Esta acción es irreversible y dejaría a los hoteles sin precios.
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiPlus, mdiArrowLeft, mdiSquareEditOutline, mdiDeleteOutline, mdiUpdate,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import NameHotelStar from '@/views/products/hotels/utils/NameHotelStar.vue'

import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    AppCardCode,
    NameHotelStar,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      isDialogVisibleDelete: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
      },
      tarifa: {
        code: uuidv4().toUpperCase().substring(28),
        name: '',
        category_id: 0,
        category_slug: '',
      },
      loading: false,
      loadingCategories: true,
      categoriesTarifarios: [],
      hotels: [],
      hotelsNomAll: [],
      isLoadingHotels: true,
      hotels_pactados: [],
      checkAll: true,
      model: null,
      seasons: [],
      seasonByCode: [],
      hotelsWithTarifarios: [],
    }
  },
  computed: {
    btnTitle() {
      return sessionStorage.getItem('hotels-contrate-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      observationsHotel: state => state.app.observationsHotel,
      idsHotelsAsociados: state => state.app.idsHotelsAsociados,
    }),
    verifyItems() {
      let result = false

      let cantCheck = 0
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.hotels.length; index++) {
        if (this.hotels[index].check) {
          cantCheck += 1
        }
      }
      if (cantCheck === 0) {
        result = true
      }

      return result
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    // this.menuIsVerticalNavMini = true
  },

  created() {
    this.getItem()
    this.getCategoriesTarifarios()
  },

  methods: {
    ...mapMutations([
      'addObservationContrateHotel',
      'updateObservationContrateHotel',
      'updateIdsHotelsAsociadosContrate',
    ]),
    getItem() {
      if (sessionStorage.getItem('hotels-contrate-id') !== null) {
        const id = sessionStorage.getItem('hotels-contrate-id')
        this.axios
          .get(`contrate_hotels/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            this.hotelsWithTarifarios = res.data.data.tarifariosByHotel
            this.seasons = this.model.seasons
            this.hotels_pactados = this.model.hotels_pactados

            this.seasons.forEach(element => {
              this.seasonByCode.push({
                code_hotel_externo: element.code_hotel_externo,
                hotels: element.hotels[0],
                publico: element.publico,
              })
            })
          })
          .finally(() => {
            this.isLoading = false
            this.getHoteles()
          })
      } else {
        this.updateIdsHotelsAsociadosContrate([])
        this.updateObservationContrateHotel([])
        this.isLoading = false
        this.isLoadingSuplementos = false
      }
    },
    getCategoriesTarifarios() {
      this.axios
        .get(
          'nom_categories_tarifarios_hotels?per_page=10000',
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.categoriesTarifarios = res.data.data
          }
        })
        .finally(() => {
          this.loadingCategories = false
        })
    },
    changeCategory() {
      if (this.tarifa.category_id) {
        this.tarifa.category_slug = this.categoriesTarifarios.filter(e => e.id === this.tarifa.category_id)[0].slug
      }
    },
    getHoteles() {
      const json = {
        page: 1,
        per_page: 100000,
        fields: [
          'id',
          'name',
          'slug',
          'operador_id',
          'tipo_destino_id',
          'direccion',
        ],
      }
      this.axios
        .post('hotels/faster', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.hotelsNomAll = res.data.data
            this.hotelsNomAll.forEach(element => {
              if (this.hotels_pactados.includes(element.id)
                  && this.hotelsWithTarifarios.includes(element.id)) {
                let publico = false
                // eslint-disable-next-line camelcase
                let code_hotel_externo = null
                this.seasonByCode.forEach(seasonByC => {
                  if (seasonByC.hotels === element.id) {
                    publico = seasonByC.publico
                    // eslint-disable-next-line camelcase
                    code_hotel_externo = seasonByC.code_hotel_externo
                  }
                })

                this.hotels.push({
                  ...element,
                  check: true,
                  publico,
                  code_hotel_externo,
                })
              }
            })
          }
        })
        .finally(() => {
          this.isLoadingHotels = false
        })
    },
    changeCheckAll() {
      if (this.checkAll) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.hotels.length; index++) {
          this.hotels[index].check = true
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.hotels.length; index++) {
          this.hotels[index].check = false
        }
      }
    },
    changeCheckItem() {
      const cantH = this.hotels.length
      let cont = 0
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.hotels.length; index++) {
        if (this.hotels[index].check) {
          cont += 1
        }
      }
      if (cont === cantH) {
        this.checkAll = true
      } else {
        this.checkAll = false
      }
    },
    confirmDelete() {
      const hotelsDelete = []
      this.hotels.forEach(element => {
        if (element.check) {
          hotelsDelete.push(element.id)
        }
      })

      const json = {
        contrate_id: sessionStorage.getItem('hotels-contrate-id'),
        hotels: hotelsDelete,
      }

      this.loading = true
      this.axios
        .post('contrate_hotels/delete-tarifarios', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          if (response.data.success === false) {
            this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))
            this.$router.push({ name: 'hotels-contrate-update' })
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.isDialogVisibleDelete = false
          this.loading = false
        })
    },
  },
}
</script>
